<template>
    <div id="tabs">
        <div class="page-title">
            <i class="uil uil-brackets-curly"></i>
            <h1>Developer Center</h1>
        </div>

        <router-link class="button" :class="{'no-bg': $route.name != 'Developers'}" to="/developers">
            <i class="uil uil-brackets-curly"></i>
            <span>Home</span>
        </router-link>
        <router-link class="button" :class="{'no-bg': $route.name != 'Projects'}" to="/developers/projects">
            <i class="uil uil-package"></i>
            <span>Projects</span>
        </router-link>
    </div>
</template>
<script>
export default {}
</script>

<style lang="scss" scoped>
#tabs {
    .button {
        margin-top: 15px;
        margin-right: 10px;
    }
}
</style>