<template>
    <div class="contents">
        <Tabs/>
        <img src="https://cdn.interaapps.de/icon/interaapps/developers-banner.svg" id="banner">
        <h2>Docs</h2>
        <br>
        <div>
            <a target="_blank" class="card" href="https://intera.dev/docs/accounts/oauth2">
                <img src="/nopb.png">
                <h1>OAuth2</h1>
            </a>
            <a target="_blank" class="card" href="https://intera.dev/docs/pastefy">
                <img src="https://cdn.interaapps.de/icon/interaapps/pastefy.png">
                <h1>Pastefy API</h1>
            </a>
            <a target="_blank" class="card" href="https://intera.dev/docs/punyshort">
                <img style="padding: 4.5px 0px; width: 70%"
                     src="https://cdn.interaapps.de/icon/interaapps/punyshort.svg">
                <h1>Punyshort API</h1>
            </a>
            <a target="_blank" class="card"
               href="https://github.com/interaapps/passwords-backend/tree/master/src/main/java/de/interaapps/passwords/backend/controller">
                <img src="https://cdn.interaapps.de/icon/interaapps/passwords.svg">
                <h1>Passwords</h1>
            </a>
        </div>

        <h2>Libraries</h2>
        <br>
        <div>
            <a target="_blank" class="card" href="https://github.com/interaapps/pastefy-java-apiclient">
                <img style="padding: 4.5px 0px; width: 70%" src="https://cdn.interaapps.de/icon/interaapps/pastefy.png">
                <h1>Pastefy Java-Client</h1>
            </a>
            <a target="_blank" class="card"
               href="https://github.com/JavaWebStack/Passport/blob/master/src/main/java/org/javawebstack/passport/services/oauth2/InteraAppsOAuth2Service.java">
                <img style="padding: 4.5px 0px; width: 70%; margin-bottom: 5px" src="/nopb.png">
                <h1 style="font-size: 18px">JWSPassport IA-OAuth2</h1>
            </a>
            <a target="_blank" class="card" href="https://developers.interaapps.de/docs/cajax">
                <img src="https://cdn.interaapps.de/icon/interaapps/cajax.svg">
                <h1>Cajax</h1>
            </a>
            <a target="_blank" class="card" href="https://developers.interaapps.de/docs/jdom">
                <img src="https://cdn.interaapps.de/icon/interaapps/jdom.svg">
                <h1>Jdom</h1>
            </a>
            <a target="_blank" class="card" href="https://github.com/interaapps/ulole-framework">
                <img style="padding: 4.5px 0px; width: 70%" src="https://cdn.interaapps.de/icon/interaapps/ulole.svg">
                <h1>Ulole-PHP Framework</h1>
            </a>
            <a target="_blank" class="card" href="https://github.com/uppm/punyshort-lib">
                <img style="padding: 4.5px 0px; width: 70%"
                     src="https://cdn.interaapps.de/icon/interaapps/punyshort-php-lib.svg">
                <h1>Punyshort PHP-Client</h1>
            </a>
            <a target="_blank" class="card" href="https://github.com/interaapps/deverm-router">
                <img style="padding: 4.5px 0px; width: 70%" src="https://cdn.interaapps.de/icon/interaapps/ulole.svg">
                <h1>Deverm Router</h1>
            </a>
            <a target="_blank" class="card" href="https://github.com/interaapps/ulole-orm">
                <img src="https://cdn.interaapps.de/icon/interaapps/ulole.svg">
                <h1>Ulole-ORM</h1>
            </a>
        </div>
        <br><br>
        <h2>Not enough?</h2><br>
        <a target="_blank" class="card" href="https://developers.interaapps.de">
            <img style="padding: 4.5px 0px; width: 70%" src="https://cdn.interaapps.de/icon/interaapps/interaapps.svg">
            <h1>Developer Center</h1>
        </a>
        <a target="_blank" class="card" href="https://github.com/interaapps">
            <img src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png">
            <h1>GitHub</h1>
        </a>
    </div>
</template>

<script>
import Tabs from "../../components/developer/Tabs";

export default {
    data: () => ({
        color: "#434343"
    }),
    components: {
        Tabs
    }
}
</script>

<style lang="scss" scoped>
#banner {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    margin-top: 24px;
}

h2 {
    color: #525252;
    margin-top: 25px;
    font-size: 30px;
}

.card {
    display: inline-block;
    width: 130px;
    border: #e4e4e4 2px solid;
    border-radius: 10px;
    color: #a0a0a0;
    text-decoration: none;
    padding: 5px 0px;
    margin-right: 10px;
    margin-bottom: 10px;

    img, img {
        display: block;
        width: 80%;
        margin: auto;
        text-align: center;
        padding: 20px 10px;
    }

    h1 {
        font-size: 20px;
        text-align: center;
    }
}
</style>